import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LocalStorageService} from "ngx-webstorage";
//import {DirectoryModel} from "../directory/service/directory.model";
import { AjustesProvider } from "../providers/ajustes";
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent {
  user;
  cerrar_menu;

  @Input()
  statusBar: boolean;
  @Output('closeMenuEvent')
  closeMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private localSt: LocalStorageService,
      public _ajustes:AjustesProvider,
      private _router:Router
    ) {
    this.user = localSt.retrieve('usr-ss');
  }

  /**
   * Validación de roles a ver la opción.
   * @param rols: Array
   */
  validateRole(rols: any[]) {
    return true;
  }

  /**
   * Ocultar menu.
   */
  closeMenu() {
    this.closeMenuEvent.emit(false);
  }

  deleteUserSession() {
    // this.localSt.clear('usr-ss');
    this._ajustes.borrarAjustes();
    this._router.navigate(['/login']);

  }
}

