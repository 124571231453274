import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <ng-progress [positionUsing]="'marginLeft'" [minimum]="0.15" [maximum]="1" [speed]="'200'" [showSpinner]="'false'"
                 [direction]="'leftToRightIncreased'" [trickleSpeed]="250" [thick]="false" [ease]="'linear'"
                 [color]="'#00e2ff'"></ng-progress>
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
  title = 'global-app';
}
