import {Routes} from '@angular/router';

/**
 * Application routes.
 * Agregar las rutas que se desean cargar, usando la carga asincrona, permitiendo optimizar
 * los tiepos de carga y rendimiento de la aplicación.
 * @routesApp Arreglo de rutas de la aplicación.
 */
const routesApp: Routes = [
  {
    path: 'faqs',
    loadChildren: 'src/app/preguntas/preguntas.module#PreguntasModule'
  },
  {
    path: 'alta-siniestro',
    loadChildren: 'src/app/alta-siniestro/alta-siniestro.module#AltaSiniestroModule'
  },
  {
    path: 'alta-mayores',
    loadChildren: 'src/app/alta-siniestros-mayores/alta-siniestros-mayores.module#AltaSiniestrosMayoresModule'
  },
  {
    path: 'consulta-siniestro',
    loadChildren: 'src/app/consulta-siniestro/consulta-siniestro.module#ConsultaSiniestroModule'
  },
  {
    path: 'cambio-masivo',
    loadChildren: 'src/app/cambio-masivo/cambio-masivo.module#CambioMasivoSiniestroModule'
  },
  {
    path: 'cargar-asegurados',
    loadChildren: 'src/app/cargar-asegurados/cargar-asegurados.module#CargarAseguradosModule'
  },
  {
    path: 'info',
    loadChildren: 'src/app/info/info.module#InfoModule'
  },
  {
    path: 'home',
    loadChildren: 'src/app/homepage/homepage.component.module#HomePageModule'
  },
  {path: '', redirectTo: '/app/home', pathMatch: 'full'},
  {path: '**', redirectTo: '/app/home'}

];

export const RoutesApplications = [...routesApp];
