import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from "../providers/rest-api";
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import { AjustesProvider } from "../providers/ajustes";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  alert:any;
  private _success = new Subject<string>();
  staticAlertClosed = false;
  mostrar_activar = false;
  username = '';
  password = '';

  constructor(private rest_api:RestApiProvider,
      private _ajustes:AjustesProvider,
      private _router:Router

    ) { 
    this.alert = {};

    this._success.subscribe((message) => this.alert.message = message);
    this._success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.alert.message= null);
  }

  activarUsuario(username:string){
    this.login(username,'activar');
    this.mostrar_activar = false;
  }

  login(username:any, password:any){
    if (username && password){
      username = username.toLowerCase();
      this.rest_api.login(username,password).then(response=>{
        console.log('responselogin  ',response);
        this._ajustes.sesion.token = response['token'];
        this._ajustes.sesion.full_name = response['full_name'];
        this._ajustes.sesion.gmm = response['gmm'];
        this._ajustes.sesion.numero_empleado = response['numero_empleado'];
        try{
          this._ajustes.sesion.logo = response['logo']['logo'];
        }
        catch(err){};
        this._ajustes.sesion.rl = response['rl'];
        if(response['status'] == 202){
          this.alert = {
            type: 'success',
            message: response['msg'],
          }
        }
        if(this._ajustes.sesion.token){
          this._ajustes.guardarStorage();
          this.alert = {
            type: 'success',
            message: 'Inicio de sesión exitoso!',
          }
          this.sendAlert();
          setTimeout(() => {
            this._router.navigate(['/app']);
          }, 1500);
        }        
      }).catch((err)=>{
        if(password == 'activar'){
          this.alert = {
            type: 'danger',
            message: 'El usuario ya fue activado o no existe, favor de verificar email o ponerse en contacto con su agente'
          }
        }
        else{
          this.alert = {
            type: 'danger',
            message: 'Credenciales inválidas'
          }
        }
        this.sendAlert();
      })
    }    
    else{
      this.alert = {
        type: 'danger',
        message: 'Credenciales inválidas',
      }
      this.sendAlert();
    }
  }

  sendAlert(){
    this._success.next(this.alert.message);
  }

  close() {
    this.alert.message=null;
  }

  forgot_password(){
    Swal.fire({
      title: 'Ingrese su correo electrónico',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Restablecer contraseña',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        if(login == ''){
          Swal.showValidationMessage(
            "Error: Ingrese un email válido"
          )
          return
        }
        return fetch(`${environment.IP_CONF}core/forgot-password/${login}`)
          .then(response => {
            console.log(response);
            
            if (response.status == 404) {
              throw new Error("El email ingresado no existe, asegurese de haber escrito correctamente y que el email ya esté activado.")
            }
            return response.status
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result);
      if (result.value == 200) {
        Swal.fire({
          title: "Correo de restablecimiento de contraseña enviado, revise su bandeja de entrada para continuar con el proceso.",
          imageUrl: "https://administradorsiniestros.s3.amazonaws.com/global/Bain/logos/Password.png"
        })
      }
    })
  }

}
