import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AjustesProvider } from "../providers/ajustes";
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $ :any;

export interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements AfterViewInit {
  statusBar = true;
  alerts:Alert[];

  constructor(
    public _ajustes:AjustesProvider,
    private _router:Router,
    private modalService: NgbModal
  ) {
    this._ajustes.cargarStorage().then(()=>{
      if(this._ajustes.sesion.token == "" || this._ajustes.sesion.token == undefined){
        this._router.navigate(['/login']);
      }
    });
    this.closeMenu();
  }

  ngAfterViewInit() {
    $('.cerrar').on('click', function(){
      $('.navbar-collapse').collapse('hide');
    });
 }

  openHelpModal(content:any){
    this.modalService.open(content, { windowClass: 'dark-modal' });
  }

  closeMenu() {
    this.statusBar = !this.statusBar;
  }

  close(alert:Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  deleteUserSession() {
    // this.localSt.clear('usr-ss');
    this._ajustes.borrarAjustes();
    this._router.navigate(['/login']);
  }
}
