import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AjustesProvider } from "./ajustes";
import { ToastrService } from 'ngx-toastr';



import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private _router:Router,
        private _ajustes:AjustesProvider,
        public toaster: ToastrService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // console.log('Http error', error);
                if (error.status == 401 || error.status == 403 || error.status == 405){
                    this.toaster.error('Error de autenticación, vuelva a iniciar sesión por favor','Error');
                    this._router.navigate(['/login']);
                    this._ajustes.borrarAjustes();
                }
                return throwError(error);
            }));
    }
}