import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RoutesApplications} from './app.routes';
import {LoginComponent} from "./login/login.component";
import {MainComponent} from "./main/main.component"

const routes: Routes = [
  {
    path: 'app',
    component: MainComponent,
    // canActivate: [UserAuthGuard],
    children: [
      ...RoutesApplications
    ]
  },
  // {
  //   path: 'public',
  //   component: PublicComponent,
  //   // canActivate: [ClientAuthGuard]
  // },
  {
    path: 'login',
    component: LoginComponent
  },
  {path: '', redirectTo: '/app', pathMatch: 'full'},
  {path: '**', redirectTo: '/app'}];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
