import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AjustesProvider } from "./ajustes"
import { environment } from '../../environments/environment';


@Injectable()
export class RestApiProvider {
  private apiUrl=''
  token = '';
  username = 'admin';
  password = '123';

  constructor(
    public _ajustes:AjustesProvider,
    private http: HttpClient,
  ) {
      this.apiUrl = environment.IP_CONF;
      this._ajustes.cargarStorage();
  }

  login(username:Text, password:Text){
    let data:any = {
      "username": username,
      "password": password
      }
    return new Promise((resolve,reject) => {
      this.http.post(this.apiUrl+'core/login',data,{headers: { 'Content-Type': 'application/json' }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  verify_email(emails:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      };
      this.http.post(this.apiUrl+'siniestros/verify-email/',emails,{headers: { 'Content-Type': 'application/json' , 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  getDependientes(id:number){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      };
      this.http.get(this.apiUrl+'siniestros/dependientes/'+id,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  search_params(text_to_search:string){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      };
      this.http.post(this.apiUrl+'siniestros/search-titular/',{'data':text_to_search},{headers: { 'Content-Type': 'application/json' , 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  

  patchUrl(url:string, data:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      };
      this.http.patch(url,data,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  getSiniestroId(id:number){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      };
      this.http.get(this.apiUrl+'siniestros/siniestros/'+id+'/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  getUrl(url:string){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      };
      this.http.get(url,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  cargar_asegurados(asegurados:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.post(this.apiUrl+'siniestros/asegurados/',asegurados,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_generos(){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/generos/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_status(){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/status/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  get_parentescos(){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/parentescos/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_composicion_familiar(){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/composiciones-familiares/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  get_afectados(pk:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'siniestros/get-familia/?pk='+pk,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_fondos(){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/tipos-fondos/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  get_tipos(){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/tiposSiniestros-mayores/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  get_planes(){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/planes/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  


  crear_siniestro(data:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.post(this.apiUrl+'siniestros/siniestros/',data,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  create_gastos(gastos:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.post(this.apiUrl+'siniestros/gastos/',gastos,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }  



  get_categorias(tipo_fondo:any, pk:number){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.get(this.apiUrl+'catalogos/subcategorias-tipos-fondos/?tipo_fondo='+tipo_fondo + '&pk=' + pk,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }  

  filter_siniestros(filters:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      let params = new HttpParams().set("titular",filters.titular)
                                  .set("numero_de_empleado",filters.numero_de_empleado)
                                  .set("status",filters.status)
                                  .set("fecha_inicio",filters.fecha_inicio)
                                  .set("fecha_fin",filters.fecha_fin)
                                  .set("mayores",filters.mayores)
                                  .set("menores",filters.menores)
                                  .set("numero_de_siniestro",filters.numero_siniestro) 
                                  .set("numero_de_folio",filters.numero_de_folio)                                   
                                  .set("busqueda_historica",filters.busqueda_historica)                                   
      this.http.get(
        this.apiUrl+'siniestros/get-siniestros/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }, 
          params: params
        })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  filter_siniestros_sinpag(filters:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      let params = new HttpParams().set("titular",filters.titular)
                                  .set("numero_de_empleado",filters.numero_de_empleado)
                                  .set("status",filters.status)
                                  .set("fecha_inicio",filters.fecha_inicio)
                                  .set("fecha_fin",filters.fecha_fin)
                                  .set("mayores",filters.mayores)
                                  .set("menores",filters.menores)
                                  .set("numero_de_siniestro",filters.numero_siniestro) 
                                  .set("numero_de_folio",filters.numero_de_folio)                                   
                                  .set("busqueda_historica",filters.busqueda_historica)                                   
      this.http.get(
        this.apiUrl+'siniestros/get-sinpag-siniestros/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }, 
          params: params
        })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }




  mkReport(filters:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      };
      let params = new HttpParams()
        .set("titular",filters.titular)
        .set("numero_de_empleado",filters.numero_de_empleado)
        .set("status",filters.status)
        .set("fecha_inicio",filters.fecha_inicio)
        .set("fecha_fin",filters.fecha_fin)
        .set("mayores",filters.mayores)
        .set("menores",filters.menores)
        .set("numero_de_siniestro",filters.numero_siniestro)  
        .set("numero_de_folio",filters.numero_de_folio)  
        .set("busqueda_historica",filters.busqueda_historica)  
      this.http.get(
        this.apiUrl+'siniestros/get-report/',
        {
          headers: 
          { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token,
          }, 
          responseType: 'blob',
          params: params
        })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }



  filter_siniestros_menores(filters:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      let params = new HttpParams().set("titular",filters.titular)
                                  .set("numero_de_empleado",filters.numero_de_empleado)
                                  .set("status",filters.status)
                                  .set("fecha_inicio",filters.fecha_inicio)
                                  .set("fecha_fin",filters.fecha_fin)
                                  .set("mayores",filters.mayores)
                                  .set("menores",filters.menores)
                                  .set("details",filters.details)
                                  .set("numero_de_siniestro",filters.numero_siniestro)  
                                  .set("numero_de_folio",filters.numero_de_folio)  
                                  .set("busqueda_historica",filters.busqueda_historica)  
                                  
      this.http.get(this.apiUrl+'siniestros/get-siniestros-menores/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }, params: params})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  filter_siniestros_menores_sin_paginacion(filters:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      let params = new HttpParams().set("titular",filters.titular)
                                  .set("numero_de_empleado",filters.numero_de_empleado)
                                  .set("status",filters.status)
                                  .set("fecha_inicio",filters.fecha_inicio)
                                  .set("fecha_fin",filters.fecha_fin)
                                  .set("mayores",filters.mayores)
                                  .set("menores",filters.menores)
                                  .set("details",filters.details)
                                  .set("numero_de_siniestro",filters.numero_siniestro)  
                                  .set("numero_de_folio",filters.numero_de_folio)  
                                  .set("busqueda_historica",filters.busqueda_historica)  
                                  
      this.http.get(this.apiUrl+'siniestros/get-siniestros-menores-sinpag/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }, params: params})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  filter_siniestros_menores_sinpaginacion(filters:any){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      let params = new HttpParams().set("titular",filters.titular)
                                  .set("numero_de_empleado",filters.numero_de_empleado)
                                  .set("status",filters.status)
                                  .set("fecha_inicio",filters.fecha_inicio)
                                  .set("fecha_fin",filters.fecha_fin)
                                  .set("mayores",filters.mayores)
                                  .set("menores",filters.menores)
                                  .set("details",filters.details)
                                  .set("numero_de_siniestro",filters.numero_siniestro)  
                                  .set("numero_de_folio",filters.numero_de_folio)  
                                  .set("busqueda_historica",filters.busqueda_historica)  
                                  
      this.http.get(this.apiUrl+'siniestros/get-sinpag-siniestros-menores/',{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }, params: params})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  delete_record(url:string){
    return new Promise((resolve,reject) => {
      if (!this._ajustes.sesion.token){
        reject('Inicie sesión nuevamente para continuar');
      }
      this.http.delete(url,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ this._ajustes.sesion.token }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }  
  
}
