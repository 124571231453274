import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgProgressModule} from "ngx-progressbar";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import {SliderComponent} from "./main/slider.component";
import {NgxWebstorageModule} from "ngx-webstorage";
import { HttpClientModule, HTTP_INTERCEPTORS }    from '@angular/common/http';
import {NgbModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { RestApiProvider } from "./providers/rest-api";
import { AjustesProvider } from "./providers/ajustes";
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FileUploadModule } from 'ng2-file-upload';
import {DpDatePickerModule} from 'ng2-date-picker';
import { HttpConfigInterceptor} from './providers/interceptor';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    SliderComponent,
    // InfoComponent,
    
  ],
  imports: [
    BrowserModule,
    FileUploadModule,
    AppRoutingModule,
    NgxWebstorageModule.forRoot(),
    NgProgressModule,
    HttpClientModule,
    NgbModule,
    NgbAlertModule,
    DpDatePickerModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
  }),
    
  ],
  providers: [
    RestApiProvider,
    AjustesProvider,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
